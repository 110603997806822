import React from "react"
import "./CategoryTab.scss"
import { createJwt } from "../../utils/helpers";
import { updateUserProfile } from "../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { changeCategoryModalStatus, setActiveExamType } from "../../utils/redux/otherSlice";
import toast from "react-hot-toast";
import { UserAuth } from "../../context/AuthContext";

const CategoryTab = () => {
    const clickedButton = useSelector((state) => state.other.clickedButton);
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log(userData.examType)
    // console.log(clickedButton)
    const dispatch = useDispatch()
    const { errorLogut } = UserAuth()


    const handleExamType = async (examType) => {
      
        const data = {
            uid: userData.uid,
            platform: "web",
            uniqueDeviceId: localStorage.getItem("uniqueDeviceId") || "",
            parameterWantToUpdate: "Exam/AcademicGeneral",
            examType: userData?.examType === 1 ? clickedButton===1 ? 1:2 : clickedButton===2? 2: 1,
            AcademicGeneral: examType 
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        const res = await updateUserProfile(formData);
        console.log(res, "test")
        if (!res.data.failure) {
            toast.success("Profile updated successfully", { id: "success" });
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            dispatch(changeCategoryModalStatus(false));
            dispatch(setActiveExamType(res.data.data.examType))
        } else {
            if (res.data.logout) {
                errorLogut(res.data.errorMessage);
            } else if (res.data.tokenInvalid) {
                toast.error(res.data.errorMessage, { id: "error" });
            } else {
                toast.error(res.data.errorMessage, { id: "error" });
            }
        }
    }

    return (
        <div className="category-container">
            <div className="container">
                <p>Select Exam Type</p>
                <div className="button-container">
                    {clickedButton === 1 ? (
                        <>
                            <button onClick={() => handleExamType(2)} className="tabs-buttons">
                                <span>Academic</span>
                            </button>
                            <button className="tabs-buttons" onClick={() => handleExamType(1)}>
                                <span>General</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => handleExamType(2)} className="tabs-buttons" >
                                <span>Academic PTE</span>
                            </button>
                            <button className="tabs-buttons" onClick={() => handleExamType(1)}>
                                <span>Core PTE</span>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CategoryTab
import React, { useState } from 'react';
import { pteReadingSpecificQuestionsFetch, pteWritingSpecificQuestionsFetch } from '../../../../api/apiCall';
import { createJwt } from '../../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestions } from '../../../../utils/redux/otherSlice';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ReactComponent as CloseGray } from '../../../../assets/svg/close_grey.svg';
import { BuyMembershipModal } from '../../ReadingTests/components/BuyMembershipModal';



const QuestionSet = ({ start, end, onClose }) => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    //const [isTestStarted, setIsTestStarted] = useState(false);
    const user = JSON.parse(localStorage.getItem("userData"));
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const catalogue = useSelector((state) => state.other.catalogue);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    const handleBuyMembership = () => {
        // Logic to handle navigation to the membership purchase page
        navigate("/buy-membership");
    };

    const handleSelect = (questionNumber) => {
        setSelectedQuestions((prevSelected) => {
            // Check if the user is paid
            const isPaidUser = user.memberShip==="Active"; // Replace this with your actual condition from state or props

            // If the user is a paid user, allow selection without restrictions
            if (isPaidUser) {
                return prevSelected.includes(questionNumber)
                    ? prevSelected.filter(q => q !== questionNumber) // Unselect if already selected
                    : [...prevSelected, questionNumber]; // Select if not selected
            }
            
            // If the user is unpaid and trying to select more than 2 questions
            if (prevSelected.length >= 2 && !prevSelected.includes(questionNumber)) {
                setServerMessage("Want to practice more than 2 reading questions a day then please buy membership.");
                setIsBuyMembership(true);
                return prevSelected; 
            }

            // Allow selection for unpaid users if they haven't reached the limit
            return prevSelected.includes(questionNumber)
                ? prevSelected.filter(q => q !== questionNumber) // Unselect if already selected
                : [...prevSelected, questionNumber]; // Select if not selected
        });
    };
    const handleQuestionClick= async ()=>{
        if (selectedQuestions.length === 0) {
            toast.error("Please select questions before starting the test!");
        } else {
            const data={
                uid: user.uid,
                platform: user.platform,
                uniqueDeviceId: user.uniqueDeviceId,
                time: user.time,
                questionNumbers:selectedQuestions,
                categoryId: catalogue.categoryId,
            };
            const encryptedData = createJwt(data);
            const formData = new FormData();
            formData.append("encrptData", encryptedData);
            try {
                const response = await pteWritingSpecificQuestionsFetch(formData);
                if (!response.data.failure) {
                      dispatch(setQuestions({
                        questions: response.data.data.questions,
                      }));
                      if(catalogue.categoryId==="0"){
                        //console.log('ab')//summarize
                        navigate('/pte/writing/Summary/Questions')
                      }
                      else if(catalogue.categoryId==="1"){
                        //console.log('cd')//essay
                        navigate('/pte/writing/Essay/Questions')
                      }
                      else if(catalogue.categoryId==="2"){
                        //console.log('ef')//email
                        navigate('/pte/writing/Email/Questions');
                      }
                    //setQuestions(response.data.data.questions); 
                    //console.log(response.data.data.questions)
                    //setIsTestStarted(true);
                } else {
                    setServerMessage(response.data.errorMessage);
                    setIsBuyMembership(true);
                }
            } catch (error) {
                toast.error("Failed to fetch questions.");
                console.error("Error fetching questions:", error);
            }
        }
    }

    

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="relative p-6 lg:left-16 bg-white rounded-xl shadow-lg">
                <div className='flex justify-between'>
                    <h2 className="text-xl font-semibold pb-4 text-left">Question List</h2>
                    <CloseGray 
                        onClick={onClose} 
                        aria-label="Close" 
                        className="w-4 h-4 cursor-pointer" 
                    />
                </div>
                <div className="flex items-center justify-start mt-4">
                    <div className="flex items-center mr-4">
                        <div className="w-4 h-4 bg-emerald-400 border rounded mr-2"></div>
                        <span className="text-gray-700">Selected</span>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-gray-300 border rounded mr-2"></div>
                        <span className="text-gray-700">Unselected</span>
                    </div>
                </div>
                <div className="py-4 px-16 grid grid-cols-3 sm:grid-cols-7 md:grid-cols-10 gap-4">
                    {Array.from({ length: end - start + 1 }, (_, index) => (
                        <button
                            key={start + index}
                            className={`font-medium py-2  px-2 xs:px-4 rounded-md cursor-pointer text-xs xs:text-sm sm:text-base transition duration-300 ease-in-out
                                        ${selectedQuestions.includes(start + index) ? 'bg-emerald-400 text-white' : 'bg-gray-300 text-gray-400 '}`}
                            onClick={() => handleSelect(start + index)}
                        >
                            {start + index}
                        </button>
                    ))}
                </div>
                <div className="flex justify-end gap-4 mt-6 ">
                    
                    <button
                        className="border border-gray-400 font-medium py-2 px-6 rounded-md "
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md "
                        onClick={handleQuestionClick}
                    >
                        Start Test
                    </button>
                </div>
            </div>
            {isBuyMembership && (
                <BuyMembershipModal
                    message={serverMessage}
                    onClose={() => setIsBuyMembership(false)}
                    onBuyMembership={handleBuyMembership}
                />
            )}
        </div>
    );
};

export default QuestionSet;

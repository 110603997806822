import { useEffect, useState } from 'react';
import star from '../../../assets/images/star.png';
import { ReactComponent as CloseGray } from '../../../assets/svg/close_grey.svg';
export const ScoreCardAll = ({ show, data, onClose,currentQuestionIndex,setCurrentQuestionIndex }) => {
    const [showAnswers, setShowAnswers] = useState(false);
    useEffect(() => {
        if (!show) {
            setShowAnswers(false);
        }
    }, [show]);
    //console.log(data)
    if (!show) return null;
    const {ans,questionText} = data[currentQuestionIndex];

    const handleShowAnswers = () => {
        if (!showAnswers) {
            setShowAnswers(true);
        }
    };
    const handleNextQuestion = () => {
        const nextIndex = currentQuestionIndex + 1;

    // Check if the next index exceeds the number of questions
        if (nextIndex >= data.length) {
            onClose(); // Close the scorecard if the limit is exceeded
        } else {
            setShowAnswers(false)
            setCurrentQuestionIndex(nextIndex); // Set the next index
        }
    };
    let formattedData = [];
    let overallResult=null;

    // Process each line
    const lines = ans.match(/[^\r\n]+/g);

    lines.forEach(line => {
        const lowerLine = line.toLowerCase();
        if (lowerLine.match(/^(1\.)?\s*overall score:/)) {
            // Extract the part after "1. overall score:" to avoid prefix
            const scorePart = line.split(':')[1].trim(); // Get the part after the colon
                overallResult = scorePart; // Convert to float
                //console.log('Found Overall Score:', overallScore); // Log the found score
        }
        formattedData.push(line); // Add the line to formattedData regardless of score
    });
  return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-2/3 max-h-[90%] overflow-auto relative">
                <div className='flex justify-between '>
                    <h2 className="text-xl font-semibold mb-2 border-2 border-blue-200 p-2 w-fit rounded-md bg-gradient-to-r from-blue-400 to-blue-600 text-white">Result</h2>
                    <CloseGray 
                        onClick={onClose} 
                        aria-label="Close" 
                        className="w-4 h-4 cursor-pointer m-1" 
                    />
                </div>
                <h3 className="mb-2 text-gray-500">Analyse your answers with the correct answers</h3>
                <div className="flex justify-center items-center mb-4">
                    <div className="w-32 h-32 bg-yellow-100 rounded-full flex items-center justify-center space-x-2 border border-orange-200">
                        {/* Circular background */}
                        <div className="text-3xl font-bold">
                            {overallResult}
                        </div>
                        {/* Star Icon */}
                        <div>
                            <img 
                                src={star}
                                alt="star-image"
                                className='w-10 h-10'
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-col space-y-4'>
                    <div className=' flex-col space-y-2'>
                            {formattedData.map((line, index) => (
                            <div key={index} className="formatted-line">
                                {line}
                            </div>
                        ))}
                    </div>
                    <div className="flex-col  space-y-2">
                        <div className='text-xl font-semibold border-2 border-blue-200 p-2 w-fit rounded-md bg-gradient-to-r from-blue-400 to-blue-600 text-white'>
                           Question
                        </div>
                        <div className='question-text px-4'>
                            {questionText}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <button
                        className="border-2 text-black py-2 px-8 rounded-md"
                        onClick={handleNextQuestion}
                    >
                        Next
                    </button>   
                </div>
            </div>
        </div>
  )
}

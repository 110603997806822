import React from 'react'

export const BuyMembershipModal = ({ message, onClose , onBuyMembership}) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80 max-w-lg text-center ">
                <p className="text-gray-800 mb-4 text-left">{message}</p>
                <div className='flex justify-end'>
                    <div className="flex justify-center space-x-4 ">
                        <button
                            onClick={onClose}
                            className="text-blue-500 px-4 py-2 rounded hover:text-blue-700"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onBuyMembership}
                            className="text-blue-500 px-4 py-2 rounded hover:text-blue-700"
                        >
                            Buy Membership
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

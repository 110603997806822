import React, { useEffect, useState } from 'react'
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi'
import ProfileIconHeader from '../../../components/ProfileIconHeader'
import { Link, useNavigate } from 'react-router-dom'
import { Divider, Grid } from "@mantine/core"
import LoadingSpinner from "../../../components/LoadingSpinner"
import WritingCatalogueCard from './components/WritingCatalogueCards/WritingCatalogueCard'
import QuestionGroup from './components/QuestionGroup'
import { useDispatch } from 'react-redux'
import { setCatalogue } from '../../../utils/redux/otherSlice'
import { createJwt } from "../../../utils/helpers"
import { pteWritingQuestionCount } from '../../../api/apiCall'

export const WritingTestCatalogue = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCatalogue, setSelectedCatalogue] = useState({ categoryId: null });
    const [totalQuestionCount, setTotalQuestionCount]=useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch= useDispatch();
    const user = JSON.parse(localStorage.getItem("userData"));
    const [writingCatalogue, setWritingCatalogue] = useState([]);

    useEffect(() => {
        const AcademicCore = user?.academicGenral; // Retrieve the examType from the user data
        let catalogue;
    
        if (AcademicCore === 2) {
            catalogue = [
                {
                    categoryName: "Summarize Written",
                    categoryId: "0",
                },
                {
                    categoryName: "Essay Writing", // For Academic examType
                    categoryId: "1",
                },
            ];
        } else if (AcademicCore === 1) {
            catalogue = [
                {
                    categoryName: "Summarize Written",
                    categoryId: "0",
                },
                {
                    categoryName: "Email Writing", // For Core examType
                    categoryId: "2",
                },
            ];
        }
    
        setWritingCatalogue(catalogue);
    }, [user?.examType]); // Re-run if examType changes

    const handleCardClick= async (catalogue)=>{
        setSelectedCatalogue(catalogue);
        dispatch(setCatalogue(catalogue));
        //console.log(selectedCatalogue)
        const data={
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            categoryId: catalogue.categoryId,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        setIsLoading(true);
        try {
            const response = await pteWritingQuestionCount(formData);
            if (response.data && !response.data.failure) {
                //console.log(response)
                setTotalQuestionCount(response.data.data.totalQuestionCount);
                //console.log(response.data.data.totalQuestionCount);
                setSelectedCatalogue(catalogue); // Update selectedCatalogue
                setModalOpen(true); // Open modal after data fetch
            } else {
                // Handle error from response
                console.error(response.data.errorMessage);
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setIsLoading(false); // Stop loading
        }
    }

    const closeModal = () => {
        setModalOpen(false);
    };
  return (
    <div className="w-full lg:max-h-screen overflow-scroll pb-5  ">
        <ProfileIconHeader />
        <main className="p-4 px-[51px] max-md:px-6">
            <div className="flex max-md:hidden items-center gap-2">
                <Link to="/home">Home</Link> <PiCaretRightBold />{" "}
                <Link to="/pte/writing">Writing</Link> <PiCaretRightBold />
                <p className="text-primary-500">Tests</p>
            </div>
            <div className="pt-5 w-fit cursor-pointer flex gap-2 items-center" onClick={() => navigate("/home")}>
                <button className="lg:hidden">
                    <PiCaretLeftBold />
                </button>
                <span className="heading">
                    Writing Question Types
                </span>
            </div>
            <Divider my="xl" />
            {isLoading ? ( // Conditional rendering for loading state
                <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
                    <LoadingSpinner /> {/* Loading spinner */}
                </div>
            ) : (
                <Grid>
                    {writingCatalogue?.map((catalogue) => (
                        <Grid.Col span={{ base: 12, sm: 12, lg: 12, xl: 6 }}>
                            <WritingCatalogueCard
                                catalogue={catalogue} 
                                onClick={() => handleCardClick(catalogue)}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            )}
            <QuestionGroup
                isOpen={isModalOpen}
                onClose={closeModal}
                catalogue={selectedCatalogue}
                totalcount={totalQuestionCount} // Passing the content based on selectedCatalogue
            />
        </main>
    </div>
  )
}

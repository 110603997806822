import React, { useState } from 'react';
import QuestionSet from './QuestionSet';
import { ReactComponent as CloseGray } from '../../../../assets/svg/close_grey.svg';

const QuestionGroup = ({ isOpen, onClose, totalcount }) => {
    const [selectedBlock, setSelectedBlock] = useState(null);
    if (!isOpen) return null; // Don't render the modal if it's not open

    const numberOfBlocks = Math.min(totalcount, 20);
    const questionsPerBlock = Math.floor(totalcount / numberOfBlocks);
    const remainingQuestions = totalcount % numberOfBlocks;

    // Calculating the range of questions for each block
    const blocks = Array.from({ length: numberOfBlocks }, (_, index) => {
        const start = index * questionsPerBlock + 1;
        const blockCount = index === numberOfBlocks - 1 ? questionsPerBlock + remainingQuestions : questionsPerBlock;
        const end = start + blockCount - 1;
        return { start, end };
    });

    const handleBlockClick = (start, end) => {
        setSelectedBlock({start,end});
    };

    const closeQuestionSet = () => {
        setSelectedBlock(null); // Reset the selected block to go back to the main modal
    };

    if(selectedBlock){
        return(
            <QuestionSet start={selectedBlock.start} end={selectedBlock.end} onClose={closeQuestionSet}  />
        );
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="relative p-6 lg:left-16 bg-white  rounded-xl shadow-lg">
                <div className='flex justify-between'>
                    <h2 className="text-lg sm:text-xl font-semibold pb-4 text-left">Select Question Set</h2>
                    <CloseGray 
                            onClick={onClose} 
                            aria-label="Close" 
                            className="w-4 h-4 cursor-pointer" 
                    />
                </div>
                <div className="py-4 px-8 sm:px-16 grid grid-cols-4 xs:grid-cols-5 gap-4">
                    {blocks.map((block, index) => (
                        <button 
                            key={index} 
                            className="border-blue-500 border text-blue-500  p-2 sm:p-4 rounded-md cursor-pointer hover:bg-blue-500 hover:text-white text-xs xs:text-sm sm:text-base"
                            onClick={() => handleBlockClick(block.start, block.end)}
                        >
                            {`${block.start}-${block.end}`} 
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuestionGroup;

import UnderDevelopment from "../../../components/UnderDevelopment"

const ListeningTestCatalogue = () => {
    return (
        <div className="w-full min-h-screen flex items-center">
            <UnderDevelopment />
        </div>
    )
}

export default ListeningTestCatalogue
import { PiCaretRightBold } from "react-icons/pi"
import FileTextSvg from "../../../../../assets/svg/filetext.svg"
import "./ReadingCatalogueCard.css"

const ReadingCatalogueCard = ({ catalogue , onClick}) => {
    return (
        <div className="catalogue-card" onClick={onClick}>
            <div className="img-container">
                <img src={FileTextSvg} alt="filetext" />
            </div>
            <div className="catalogue-heading">
                {catalogue.categoryName}
            </div>
            <div className="card-icon">
                <PiCaretRightBold />
            </div>
        </div>
    )
}

export default ReadingCatalogueCard
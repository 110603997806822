import React, { useEffect, useRef, useState } from 'react';
import { analysisPteReadingQuestionAnswers, pteReadingQuestionPaperFetch } from '../../../api/apiCall';
import { createJwt } from '../../../utils/helpers';
import LoadingSpinner from '../../../components/LoadingSpinner';
import toast, { CheckmarkIcon } from 'react-hot-toast';
import { FillModal } from './components/FillModal';
import ScoreCardMain from './ScorecardMain';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { resetRetry, setRetry } from '../../../utils/redux/otherSlice';
import { useNavigate } from 'react-router-dom';
import { BuyMembershipModal } from './components/BuyMembershipModal';

export const ReadingMain = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [questionPaper, setQuestionPaper] = useState(null);
    const [questionPaperId, setQuestionPaperId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0); // Track current category
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
    const [rwAnswers, setRWAnswers] = useState([]); // r and w
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([]);// multiple choice 
    const [multipleChoiceSingleAnswer, setMultipleChoiceSingleAnswer] = useState(""); // multiple choice single answer
    const [fillInTheBlanksAnswer, setFillInTheBlanksAnswer] = useState([]); // fill in the blanks
    const [fillAnswerOptions, setFillAnswersOptions] = useState([]); // answer options specific for  fill in the blanks
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [focusedBlank, setFocusedBlank] = useState(-1);// for fill in the blank
    const [focusedIndex, setFocusedIndex] = useState(-1); // for R and W
    const [leftSentences, setLeftSentences] = useState([]);// for reorder paragraph
    const [rightSentences, setRightSentences] = useState([]);
    const [selectedSentence, setSelectedSentence] = useState(null);
    const [ans, setAns] = useState({});
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const dispatch = useDispatch();
    const isRetry = useSelector((state) => state.other.retry); 
    const navigate= useNavigate()
    const hasFetched = useRef(false);
    const [isFinalStage, setIsFinalStage] = useState(false);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    const paperFetch = async () => {
        const data = {
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        
        try {
            const response = await pteReadingQuestionPaperFetch(formData);
            console.log(response)
            if (response.data && !response.data.failure) {
                setQuestionPaperId(response.data.data.questionPaperId);
                setQuestionPaper(response.data.data.questionPaper);
            } else {
                setServerMessage(response.data.errorMessage);
                setIsBuyMembership(true)
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setLoading(false);
        }
    };

    const handlePaperFetch = () => {
        if(loading){
            if (!isRetry) {
                paperFetch();
            
            } else {
                const newPaperData = JSON.parse(localStorage.getItem("newpaper"));
                if (newPaperData) {
                    const { newQuestionId, paper } = newPaperData;
                    setQuestionPaperId(newQuestionId.questionPaperId);
                    setQuestionPaper(paper);
                    dispatch(resetRetry());
                } else {
                    console.error("No paper data found in localStorage.");
                }
                setLoading(false);
            }
            
        }
    }
   
    useEffect(() => {
        if (!hasFetched.current) {
            handlePaperFetch();
            hasFetched.current = true;  // Mark as fetched
        }
    }, []);

    const categories = questionPaper ? Object.keys(questionPaper) : [];

    const currentCategory = categories[currentCategoryIndex];
    const questions = currentCategory ? questionPaper[currentCategory] : [];


    useEffect(() => {
        if (questionPaper) {

            if (questions && questions.length > 0) {
                if(currentCategory==='RandWFillInTheBlank'){
                    const totalBlanks = Object.keys(questions[currentQuestionIndex].AnswerOptions).length;
                    setRWAnswers(Array(totalBlanks).fill(""));
                } // Reset answers array to match the current question
                else if(currentCategory==='MultipleChoiceMultipleAnswer'){
                    setMultipleChoiceAnswers([]);
                }
                else if(currentCategory==='MultipleChoiceSingleAnswer'){
                    setMultipleChoiceSingleAnswer("")
                }
                else if(currentCategory==='FillInTheBlank'){
                    const totalBlanks = (questions[currentQuestionIndex].questionText.match(/\*+/g) || []).length;

                    setFillInTheBlanksAnswer(Array(totalBlanks).fill(''));
                    
                    setFillAnswersOptions(questions[currentQuestionIndex].AnswerOptions);
                }
                else{
                    setLeftSentences(questions[currentQuestionIndex].questionText)
                    setRightSentences(Array(questions[currentQuestionIndex].questionText.length).fill(null));
                }
            }
        }
    }, [questionPaper, currentCategoryIndex, currentQuestionIndex]);

    useEffect(() => {
        if (isFinalStage) {
            const data = {
                uid: user.uid,
                platform: user.platform,
                uniqueDeviceId: user.uniqueDeviceId,
                time: user.time,
                questionPaperId: questionPaperId,
                answer: ans // Using the updated answers
            };

           // console.log(data)
    
            const encryptedData = createJwt(data);
            const formData = new FormData();
            formData.append("encrptData", encryptedData);
            setLoading(true)
            const fetchResults = async () => {
                try {
                    const response = await analysisPteReadingQuestionAnswers(formData);
                    if (!response.data.failure) {
                        const res = response.data.data;
                        if (isRetry) {
                            console.log("Retry test data");
                        }
    
                        const dataToStore = {
                            results: res, // Store the results from API
                            questionPaper: questionPaper // The question paper
                        };
                        //console.log(dataToStore.results.overallResult)
    
                        // Save in localStorage
                        localStorage.setItem(questionPaperId, JSON.stringify(dataToStore));
                        // const {results}=JSON.parse(localStorage.getItem(questionPaperId));
                        // console.log(results)

    
                        // Update score data and show the scorecard
                        setScoreData(res);
                        setShowScorecard(true);
                    } else {
                        toast.error(response.data.errorMessage);
                        console.error(response.data.errorMessage);
                    }
                } catch (error) {
                    toast.error("Failed to fetch questions.");
                    console.error("Error fetching questions:", error);
                }finally {
                    setLoading(false); // Stop loading
                }
            };
    
            fetchResults();
        }
    }, [isFinalStage]);

    const handleBuyMembership = () => {
        // Logic to handle navigation to the membership purchase page
        navigate("/buy-membership");
    };

    const handleMembershipModalClose=()=>{
        setIsBuyMembership(false);
        navigate(-1)
    }

    const handleAnswerChange = (index, value) => {
        setRWAnswers(prevAnswers => {
            const updatedAnswers = [...prevAnswers]; // Spread to create a copy
            updatedAnswers[index] = value; // Update the specific index
            return updatedAnswers;
        });
    };
    const handleAnswerChange2 = (answerOption) => {
        setMultipleChoiceAnswers(prevAnswers => {
            if (prevAnswers.includes(answerOption)) {
                // Remove the answer if it is already selected
                return prevAnswers.filter(answer => answer !== answerOption);
            } else {
                // Add the answer if it is not selected
                return [...prevAnswers, answerOption];
            }
        });
    };

    const handleAnswerChange3 = (answerOption) => {
        setMultipleChoiceSingleAnswer(answerOption); // Set selected answer directly
    };

    const isOptionSelected = (option) => fillInTheBlanksAnswer.includes(option);

    const handleAnswerSelection = (option) => {
        setFillInTheBlanksAnswer((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[focusedBlank] = option; // Set the answer for the focused blank
            return updatedAnswers;
        });
        setIsModalOpen(false); // Close modal after selection
    };

    const moveToRight = () => {
        if (selectedSentence !== null && leftSentences.includes(selectedSentence)) {
          // Find the index of the selected sentence in leftSentences
          const index = leftSentences.indexOf(selectedSentence);
          
          // Remove it from leftSentences and add it to the first empty slot in rightSentences
          if (index !== -1) {
            const newLeftSentences = [...leftSentences];
            newLeftSentences[index] = null;
            
            const newRightSentences = [...rightSentences];
            const emptyIndex = newRightSentences.indexOf(null);
            if (emptyIndex !== -1) {
              newRightSentences[emptyIndex] = selectedSentence;
            }
            
            setLeftSentences(newLeftSentences);
            setRightSentences(newRightSentences);
            setSelectedSentence(null); // Clear selection after moving
          }
        }
      };
      
      const moveToLeft = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
          // Find the index of the selected sentence in rightSentences
          const index = rightSentences.indexOf(selectedSentence);
          
          // Remove it from rightSentences and add it to the first empty slot in leftSentences
          if (index !== -1) {
            const newRightSentences = [...rightSentences];
            newRightSentences[index] = null;
            
            const newLeftSentences = [...leftSentences];
            const emptyIndex = newLeftSentences.indexOf(null);
            if (emptyIndex !== -1) {
              newLeftSentences[emptyIndex] = selectedSentence;
            }
            
            setRightSentences(newRightSentences);
            setLeftSentences(newLeftSentences);
            setSelectedSentence(null); // Clear selection after moving
          }
        }
      };

      const moveUp = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
            const index = rightSentences.indexOf(selectedSentence);
            if (index > 0) {
                const newRightSentences = [...rightSentences];
                // Swap the selected sentence with the one above it
                [newRightSentences[index], newRightSentences[index - 1]] = [newRightSentences[index - 1], newRightSentences[index]];
                setRightSentences(newRightSentences);
                setSelectedSentence(newRightSentences[index - 1]); // Update the selected sentence
            }
        }
    };
    
    const moveDown = () => {
        if (selectedSentence !== null && rightSentences.includes(selectedSentence)) {
            const index = rightSentences.indexOf(selectedSentence);
            if (index < rightSentences.length - 1) {
                const newRightSentences = [...rightSentences];
                // Swap the selected sentence with the one below it
                [newRightSentences[index], newRightSentences[index + 1]] = [newRightSentences[index + 1], newRightSentences[index]];
                setRightSentences(newRightSentences);
                setSelectedSentence(newRightSentences[index + 1]); // Update the selected sentence
            }
        }
    };

    const onDragStart = (sentence) => {
        setSelectedSentence(sentence);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDrop = (targetArray) => {
        if (selectedSentence) {
            if (targetArray === 'right') {
                moveToRight(selectedSentence); // Move selected sentence to right
            } else if (targetArray === 'left') {
                moveToLeft(selectedSentence); // Move selected sentence to left
            }
        }
    }


    // Helper function to render question based on its type
    const RenderRandWFillInTheBlank = (currentQuestion) => {
        const parts = currentQuestion.questionText.split(/(\*+)/); // Split the text by asterisks
        let blankCounter = 0;
        const totalBlanks = Object.keys(currentQuestion.AnswerOptions).length;

        return (
        <div>
         <div className='font-semibold  leading-10 text-orange-500'>
                    Below is a text with blank ,a list of choices will appear. Select the appropriate answer for each blanks.
            </div>
        <h3 className="font-semibold py-2 px-28 leading-10">
        {parts.map((part, index) => {
            if (/^\*+$/.test(part)) {
                const currentCounter = blankCounter;
                blankCounter++;
                const options = currentCounter < totalBlanks ? Object.entries(currentQuestion.AnswerOptions)[currentCounter][1] : [];
                return (
                    <select
                        key={`blank-${currentCounter}`} // Use blankCounter as the key
                        style={{
                            padding: '0.25rem 0.5rem', // Equivalent to py-1 px-2
                            border: '2px solid',
                            borderColor: focusedIndex === currentCounter ? 'blue' : 'gray',
                            borderRadius: '0.375rem', // Equivalent to rounded-md
                            color: rwAnswers[currentCounter] ? 'black' : 'gray' // Change text color based on answer
                        }}
                        className={`${focusedIndex === currentCounter ? 'border-blue-500' : 'border-gray-400'}`}
                        onChange={(e) => handleAnswerChange(currentCounter, e.target.value)} // Update answer on change
                        value={rwAnswers[currentCounter] || ""} // Use answers for current blank
                        onFocus={() => setFocusedIndex(currentCounter)} // Set focused index on focus
                        onBlur={() => setFocusedIndex(-1)} // Clear focused index on blur
                    >
                        <option value="" disabled style={{ color: 'gray' }}>Fill in the blanks</option>
                        {options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option} style={{ color: 'black' }}>{option}</option>
                        ))}
                    </select>
                );
            }

            return <span key={index}>{part}</span>; // Render the text part
        })};
        </h3>
        </div>
        )
    };

    const RenderMultipleChoiceMultipleAnswer =(currentQuestion)=>{
        return(
        <div className=' px-4 flex-col space-y-2'>
        <div className='font-semibold  leading-10 text-orange-500'>
            Multiple-Choice-Read the text and answer the multiple-choice question by selecting the correct responses. More than one response is correct
        </div>
        <div className="font-semibold py-2 px-32 leading-10">{currentQuestion.questionText}</div>
            <div className="py-2 px-28">
                <div className='flex flex-col px-4 space-y-2'>
                    {Object.entries(currentQuestion.AnswerOptions).map(([key, answerOption], index) => (
                        <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm ">
                            <input
                                type="checkbox"
                                className="hidden"
                                checked={multipleChoiceAnswers.includes(answerOption)}
                                onChange={(e) => handleAnswerChange2(answerOption)}
                            />
                                <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${multipleChoiceAnswers.includes(answerOption) ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                {multipleChoiceAnswers.includes(answerOption) && (
                                    <CheckmarkIcon className='bg-blue-600'/>
                                )}
                            </span>
                            <span>{answerOption}</span>
                        </label>
                    ))}
                </div>
            </div>
        </div>
        );
    };
    const RenderMultipleChoiceSingleAnswer =(currentQuestion)=>{
        return(
            <div className='px-4 flex-col space-y-2'>
                <div className='font-semibold leading-10 text-orange-500'>
                    Multiple-Choice-Read the text and answer the multiple-choice question by selecting the correct responses. One response is correct, choose single answer.
                </div>
                <div className="font-semibold py-2 px-32 leading-10">{currentQuestion.questionText}</div>
                <div className="py-2 px-28">
                    <div className='flex flex-col px-4 space-y-2'>
                        {Object.entries(currentQuestion.AnswerOptions).map(([key, answerOption], index) => (
                            <label key={key} className="flex items-center space-x-2 border-2 p-4 rounded-md shadow-sm">
                                <input
                                    type="radio" // Change to radio input
                                    className="hidden"
                                    checked={multipleChoiceSingleAnswer === answerOption} // Check if this answer is selected
                                    onChange={() => handleAnswerChange3(answerOption)} // Set selected answer
                                />
                                <span className={`flex items-center justify-center w-6 h-6 border-2 rounded-full flex-shrink-0 ${multipleChoiceSingleAnswer === answerOption ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}>
                                    {multipleChoiceSingleAnswer === answerOption && (
                                        <CheckmarkIcon className='bg-blue-600' />
                                    )}
                                </span>
                                <span>{answerOption}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    const RenderFillInTheBlank =(currentQuestion)=>{
        const parts = currentQuestion.questionText.split(/(\*+)/); // Split text by asterisks
        let blankCounter = 0;
        return(
            <div>
                <div className='font-semibold  leading-10 text-orange-500'>
                    Below is a text with blank ,a list of choices will appear. Select the appropriate answer for each blanks.
                </div>
            <h3 className="font-semibold py-2 px-28 leading-10">
                {parts.map((part, index) => {
                        if (/^\*+$/.test(part)) {
                            const currentBlank = blankCounter;
                            blankCounter++;

                            return (
                                <button
                                    key={`blank-${currentBlank}`}
                                    onClick={() => {
                                        setFocusedBlank(currentBlank); // Set focused blank
                                        setIsModalOpen(true); // Open modal on click
                                    }}
                                    className={`px-2 py-1 my-2 border-2 rounded-md min-w-[140px] whitespace-nowrap ${fillInTheBlanksAnswer[currentBlank] ? 'text-black border-gray-500' : 'text-gray-500 border-gray-400'}`}
                                >
                                    {fillInTheBlanksAnswer[currentBlank] || 'Select your answer'}
                                </button>
                            );
                        }
                        return <span key={index}>{part}</span>; // Render text normally
                    })
                }
            </h3>
            </div>
        )
    }

    const RenderReOrderParagraph =(currentQuestion)=>{
        return(
            <div className=' px-4 flex-col space-y-2'>
            <div className='font-semibold  leading-10 text-orange-500'>
                Below is a paragraph. Please reorder it to arrange the content in the correct sequence 
            </div>
            <div className="font-semibold py-2 px-4 xs:px-32 ">
                <div className="flex flex-col items-center sapce-y-4 xl:flex-row  xl:justify-center xl:space-x-4 p-4">
                    
                    {/* Left container */}
                    <div className="p-4 border-2 border-gray-300 rounded-md w-3/4 h-fit"
                        onDrop={() => onDrop('left')} // Handle the drop event
                        onDragOver={onDragOver}
                    >
                        <h3 className="text-lg font-semibold mb-4">Source</h3>
                        <div className="flex flex-col gap-2">
                            {Array(leftSentences.length).fill(null).map((_, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-center py-2 px-4 items-center min-h-20 rounded-md w-full bg-gray-200 ${leftSentences[index] ? 'cursor-pointer' : ''}`}
                                    onClick={() => leftSentences[index] && setSelectedSentence(leftSentences[index])}
                                    draggable={!!leftSentences[index]}
                                    onDragStart={() => leftSentences[index] && onDragStart(leftSentences[index])}
                                >
                                    <span className={`whitespace-normal break-words  text-left ${selectedSentence === leftSentences[index] ? 'text-blue-500' : ''}`} style={{ width: '100%' }}>{leftSentences[index] || ''}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="flex flex-col space-y-4   justify-center">
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveToRight}
                            
                        >
                            →
                        </button>
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveToLeft}
                            
                        >
                            ←
                        </button>
                    </div>

                    {/* Right container */}
                    <div className="p-4 border-2 border-gray-300 rounded-md w-3/4 h-fit"
                         onDrop={() => onDrop('right')} // Handle the drop event
                         onDragOver={onDragOver} //
                    >
                        <h3 className="text-lg font-semibold mb-4">Target</h3>
                        <div className="flex flex-col gap-2">
                            {Array(rightSentences.length).fill(null).map((_, index) => (
                            <div
                                key={index}
                                className={`flex justify-center items-center py-2 px-4 min-h-20 rounded-md w-full bg-gray-200 ${rightSentences[index] ? 'cursor-pointer' : ''}`}
                                onClick={() => rightSentences[index] && setSelectedSentence(rightSentences[index])}
                                draggable={!!rightSentences[index]}
                                onDragStart={() => rightSentences[index] && onDragStart(rightSentences[index])}
                            >
                                <span className={`whitespace-normal break-words text-left ${selectedSentence === rightSentences[index] ? 'text-blue-500' : ''}`} style={{ width: '100%' }}>{rightSentences[index] || ''}</span>
                            </div>
                            ))}
                        </div>
                    </div>
                    {/* Up and Down Buttons */}
                    <div className="flex flex-col space-y-4 mt-4 justify-center">
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveUp}
                            disabled={ rightSentences.indexOf(selectedSentence) === 0}
                        >
                            ↑
                        </button>
                        <button
                            className="text-xl text-blue-500 border-2 border-blue-500 px-4 py-2 rounded-md"
                            onClick={moveDown}
                            disabled={ rightSentences.indexOf(selectedSentence) === rightSentences.length - 1}
                        >
                            ↓
                        </button>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    const renderQuestion = (category, question) => {
        switch (category) {
            case 'FillInTheBlank':
                return RenderFillInTheBlank(question);
            case 'MultipleChoiceMultipleAnswer':
                return RenderMultipleChoiceMultipleAnswer(question);
            case 'MultipleChoiceSingleAnswer':
                return RenderMultipleChoiceSingleAnswer(question);
            case 'RandWFillInTheBlank':
                return RenderRandWFillInTheBlank(question);
            case 'ReOrderParagraph':
                return RenderReOrderParagraph(question);
            default:
                return <div>Unknown question type</div>;
        }
    };

    const handleNext = async() => {
        let updatedAns = { ...ans }; // Create a copy of the current ans object

        if (currentCategory === 'RandWFillInTheBlank') {
          const allAnswersSelected = rwAnswers.every(answer => answer !== "");
          if (!allAnswersSelected) {
            toast.error('Please select all answers.');
            return;
          }
          
          const formattedAnswers = {};
          rwAnswers.forEach((answer, index) => {
            formattedAnswers[String(index + 1)] = answer;
          });
    
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: formattedAnswers,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
        } else if (currentCategory === 'MultipleChoiceMultipleAnswer') {
          if (multipleChoiceAnswers.length === 0) {
            toast.error("Please select at least one answer.");
            return;
          }
    
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: multipleChoiceAnswers,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
        } else if (currentCategory === 'MultipleChoiceSingleAnswer') {
            console.log(questionPaper[currentCategory])
            if (multipleChoiceSingleAnswer.trim() === "") {
                toast.error("Please select an answer.");
                return;
            }
        
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
            }
            updatedAns[currentCategory].push({
                answer: multipleChoiceSingleAnswer,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
            });
        } else if (currentCategory === 'FillInTheBlank') {
          const allAnswersSelected = fillInTheBlanksAnswer.every((answer) => answer !== '');
          if (!allAnswersSelected) {
            toast.error('Please select all answers.');
            return;
          }
    
          const formattedAnswers = {};
          fillInTheBlanksAnswer.forEach((answer, index) => {
            formattedAnswers[String(index + 1)] = answer;
          });
    
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: formattedAnswers,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
        } else {
          if (rightSentences.includes(null)) {
            toast.error("Please ensure all selected answers are valid.");
            return;
          }
    
          if (!updatedAns[currentCategory]) {
            updatedAns[currentCategory] = [];
          }
          updatedAns[currentCategory].push({
            answer: rightSentences,
            uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
          });
        }
        setAns(updatedAns)
        if (currentQuestionIndex < questions.length - 1) {
            // Move to next question in the same category
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentCategoryIndex < categories.length - 1) {
            // Move to first question of the next category
            setCurrentCategoryIndex(currentCategoryIndex + 1);
            setCurrentQuestionIndex(0);
        } else {
            setIsFinalStage(true);
        }
    };

    const formatCategory =(category) =>{
        if (category === 'RandWFillInTheBlank') {
            return 'R and W Fill In The Blank';
        } else if (typeof category === 'string') {
            return category.replace(/([a-z])([A-Z])/g, '$1 $2');
        } else {
            return ''; // or any other default value you'd like to return if `category` is null or not a string
        }
    }
    

    const handleClose = () =>{
        setShowScorecard(false);
        navigate(-1)
    }
   if(loading){
    return(
        <LoadingSpinner/>
    )
   }
    return (
        <div className='p-4'>
            <h1 className='font-semibold text-4xl'>Reading Test</h1>
            <h2 className='text-lg'>{formatCategory(categories[currentCategoryIndex])}</h2>
            <div className="question-container">
                {questionPaper && questions.length > 0 ? (
                    <div>
                        <h3>Question {currentQuestionIndex + 1} of {questions.length }</h3>
                        {/* Render the current question based on its type */}
                        <div>
                            {renderQuestion(categories[currentCategoryIndex],questions[currentQuestionIndex])}
                        </div>
                        <div className="flex justify-end mt-4 ">
                            <button onClick={handleNext} className="py-2 bg-blue-500 text-white rounded-md px-4">
                                Analyse Answer
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>No questions in this category.</div>
                )}
            </div>
    
            <ScoreCardMain
                show={showScorecard}
                data={scoreData}
                onClose={() => handleClose()}
                canRetry={false}
            />
            <FillModal
                isModalOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                answerOptions={fillAnswerOptions}
                handleAnswerSelection={handleAnswerSelection}
                isOptionSelected={isOptionSelected}
            />
            {isBuyMembership && (
                <BuyMembershipModal
                    message={serverMessage}
                    onClose={handleMembershipModalClose}
                    onBuyMembership={handleBuyMembership}
                />
            )}
        </div>
    );
};

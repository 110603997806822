import React from 'react';

export const FillModal = ({ isModalOpen, onClose, answerOptions, handleAnswerSelection, isOptionSelected }) => {
  if (!isModalOpen) return null; // Don't render if modal is closed

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" // Modal overlay
      onClick={onClose} // Close modal on overlay click
    >
      <div 
        className="bg-white p-6 rounded-lg space-y-4 w-96" 
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <h2 className="text-xl font-semibold">Select an answer:</h2>
        <ul className="space-y-2">
          {answerOptions.map((option, index) => (
            <li key={index}>
              <button
                className={`w-full px-4 py-2 border ${isOptionSelected(option) ? 'text-gray-500' : ''}`}
                onClick={() => handleAnswerSelection(option)}
                disabled={isOptionSelected(option)} // Disable if already selected
              >
                {option}
              </button>
            </li>
          ))}
        </ul>
        <button 
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

import React from 'react';

const RetryModal = ({ onClose, onBuyMembership, isOpen }) => {
    if (!isOpen) return null; 

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-1/3 relative">
                <h2 className="text-xl font-bold mb-2">Membership Required</h2>
                <h3 className="mb-2 text-gray-500">Want to retry this test?</h3>
                <div className="flex justify-center items-center mb-4">
                    <button
                        onClick={onBuyMembership}
                        className="ml-2 border-2 border-white bg-red-500 rounded-md p-2 text-white"
                    >
                        Buy Membership
                    </button>
                    <button
                        onClick={onClose}
                        className="ml-2 border-2 border-white bg-gray-300 rounded-md p-2 text-black"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RetryModal;

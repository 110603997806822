import React from "react";
import { useNavigate } from "react-router-dom";

const UnderDevelopment = () => {
  const navigate = useNavigate(); // Use the useNavigate hook

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="w-full h-full flex items-center justify-center p-4 bg-background">
      <div className="flex flex-col gap-3 justify-center items-center">
        <h1 className="text-2xl md:text-3xl font-semibold text-center">This page is under development</h1>
        <button className="primary-btn px-4" onClick={handleBackClick}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default UnderDevelopment;

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createJwt } from '../../../utils/helpers';
import { analysisPteWritingQuestionAnswers, pteWritingQuestionPaperFetch } from '../../../api/apiCall';
import { resetRetry } from '../../../utils/redux/otherSlice';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { BuyMembershipModal } from '../ReadingTests/components/BuyMembershipModal';
import toast from 'react-hot-toast';
import { ScoreCardMain } from './ScoreCardMain';
import LoadingOverlay from './LoadingOverlay';

export const WritingMain = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [questionPaper, setQuestionPaper] = useState(null);
    const [questionPaperId, setQuestionPaperId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [overlayloading, setOverlayLoading] = useState(false);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0); // Track current category
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
    const [answer1, setAnswer1] = useState(''); // Summary category
    const [answer2, setAnswer2] = useState('');// Email category
    const [answer3, setAnswer3] = useState('');// Essay category
    const [wordCount1, setWordCount1] = useState(0);
    const [wordCount2, setWordCount2] = useState(0);
    const [wordCount3, setWordCount3] = useState(0);
    const [ans, setAns] = useState({});
    const [showScorecard, setShowScorecard] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const dispatch = useDispatch();
    const isRetry = useSelector((state) => state.other.retry); 
    const navigate= useNavigate()
    const hasFetched = useRef(false);
    const [isFinalStage, setIsFinalStage] = useState(false);
    const [isBuyMembership, setIsBuyMembership] = useState(false);
    const [serverMessage, setServerMessage] = useState("");

    const paperFetch = async () => {
        const data = {
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        
        try {
            const response = await pteWritingQuestionPaperFetch(formData);
            //console.log(response.data.data.questionPaper.WritingSummarize)
            if (response.data && !response.data.failure) {
                setQuestionPaperId(response.data.data.questionPaperId);
                setQuestionPaper(response.data.data.questionPaper);
            } else {
                setServerMessage(response.data.errorMessage);
                setIsBuyMembership(true)
            }
        } catch (err) {
            console.error("Error fetching question count:", err);
        } finally {
            setLoading(false);
        }
    };

    const handlePaperFetch = () => {
        if(loading){
            if (!isRetry) {
                paperFetch();
            
            } else {
                const newPaperData = JSON.parse(localStorage.getItem("newpaper"));
                if (newPaperData) {
                    const { newQuestionId, paper } = newPaperData;
                    setQuestionPaperId(newQuestionId.questionPaperId);
                    setQuestionPaper(paper);
                    dispatch(resetRetry());
                } else {
                    console.error("No paper data found in localStorage.");
                }
                setLoading(false);
            }
            
        }
    }
   
    useEffect(() => {
        if (!hasFetched.current) {
            handlePaperFetch();
            hasFetched.current = true;  // Mark as fetched
        }
    }, []);

    const categories = questionPaper ? Object.keys(questionPaper) : [];

    const currentCategory = categories[currentCategoryIndex];
    const questions = currentCategory ? questionPaper[currentCategory] : [];

    useEffect(() => {
        if (questionPaper) {

            if (questions && questions.length > 0) {
                setAnswer1('');
                setWordCount1(0)
                setAnswer2('');
                setWordCount2(0)
                setAnswer3('');
                setWordCount3(0)
            }
        }
    }, [questionPaper,currentQuestionIndex,currentCategoryIndex,showScorecard]);

    const formAnswers = async (queryId) => {
        const data = {
            uid: user.uid,
            platform: user.platform,
            uniqueDeviceId: user.uniqueDeviceId,
            time: user.time,
            questionPaperId: questionPaperId,
            queryId:queryId,
            answer: ans // Using the updated answers
        };
        const encryptedData = createJwt(data);
        const formData = new FormData();
        formData.append("encrptData", encryptedData);
        try{
            const response=await analysisPteWritingQuestionAnswers(formData)
            if (response.data.failure) {
                toast.error(response.data.errorMessage);
                return; // Exit if there's an error
            }
            else{
                return response.data.data;
            }
        }
        catch (error) {
            toast.error("Failed to fetch questions.");
            console.error("Error fetching questions:", error);
        }

    };

    const handleAnswerSubmit = async ()=>{
        let queryId = '';
        let response;
        setOverlayLoading(true)
        do {
            // Call the formAnswers function to get the response
            response = await formAnswers(queryId);
            
            if (response) {
                queryId = response.queryId; // Update queryId with the latest value
                const callbackTime = response.callBackTime; // Get the callback time
    
                await new Promise((resolve) => setTimeout(resolve, callbackTime)); // Wait for the callback time
            }
        } while (response && Object.keys(response.Result).length === 0);
        setOverlayLoading(false)
        const dataToStore = {
            results: response, // Store the results from API
            questionPaper: questionPaper // The question paper
        };
        //console.log(dataToStore.results.overallResult)

        // Save in localStorage
        localStorage.setItem(questionPaperId, JSON.stringify(dataToStore));
        setScoreData(response); // Set score data from response
        setShowScorecard(true);
        //console.log(response.Result)
    }

    useEffect(() => {
        if (isFinalStage) {
            handleAnswerSubmit()
        }
    }, [isFinalStage]);

    const handleBuyMembership = () => {
        // Logic to handle navigation to the membership purchase page
        navigate("/buy-membership");
    };

    const handleMembershipModalClose=()=>{
        setIsBuyMembership(false);
        navigate(-1)
    }

    // handle answer changes

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };


    const handleTextChange1 = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount1(words);
        setAnswer1(value);
    }

    const handleTextChange2 = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount2(words);
        setAnswer2(value);
    }
    const handleTextChange3 = (e) => {
        const value = e.target.value;
        const words = countWords(value);
        setWordCount3(words);
        setAnswer3(value);
    }

    const WritingSummarize = (question)=>{
        return(
            <div className=' px-4 flex-col space-y-2 '>
                <div className='font-semibold  leading-10 text-orange-500 '>
                    <span className='font-bold '>Read Paragraph below, </span>and answer by writing your answer.
                </div>
                <div className="font-semibold py-2 px-32 leading-10">{question.questionText}</div>
                <div className="relative px-32">
                    <textarea 
                        className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={answer1}
                        placeholder="Start writing your answer here..." 
                        rows="15"
                        onChange={handleTextChange1} // You need this function to handle changes
                    />

                    {/* Character Counter */}
                    <div className="text-right text-gray-500 pt-2">
                        {wordCount1}/75
                    </div>
                </div>
            </div>
        )
    }
    const WritingEmail = (question)=>{
        return(
            <div className=' px-4 flex-col space-y-2 '>
                <div className='font-semibold  leading-10 text-orange-500 '>
                    <span className='font-bold '>Read Paragraph below, </span>and answer by writing your answer.
                </div>
                <div className="font-semibold py-2 px-32 leading-10">{question.questionText}</div>
                <div className="relative px-32">
                    <textarea 
                        className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={answer2}
                        placeholder="Start writing your answer here..." 
                        rows="15"
                        onChange={handleTextChange2} // You need this function to handle changes
                    />

                    {/* Character Counter */}
                    <div className="text-right text-gray-500 pt-2">
                        {wordCount2}/120
                    </div>
                </div>
            </div>
        )
    }
    const WritingEssay = (question)=>{
        return (
            <div className=' px-4 flex-col space-y-2 '>
                <div className='font-semibold  leading-10 text-orange-500 '>
                    <span className='font-bold '>Read Paragraph below, </span>and answer by writing your answer.
                </div>
                <div className="font-semibold py-2 px-32 leading-10">{question.questionText}</div>
                <div className="relative px-32">
                    <textarea 
                        className="w-full p-2 bg-gray-100  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={answer3}
                        placeholder="Start writing your answer here..." 
                        rows="15"
                        onChange={handleTextChange3} // You need this function to handle changes
                    />

                    {/* Character Counter */}
                    <div className="text-right text-gray-500 pt-2">
                        {wordCount3}/300
                    </div>
                </div>
            </div>
        )
    }
    const renderTextBox = (category, question) => {
        switch (category) {
            case 'WritingSummarize':
                return WritingSummarize(question);
            case 'WritingEmail':
                return WritingEmail(question);
            case 'WritingEssay':
                return WritingEssay(question);
            default:
                return <div>Unknown question type</div>;
        }
    }

    const handleNext = async() => {
        let updatedAns = { ...ans }; 
        if(currentCategory==='WritingSummarize'){
            if (wordCount1<75) {
                // Show a notification if no answer is selected
                toast.error("Minimum Word Count is 75");
                return; 
            }
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
              }
              updatedAns[currentCategory].push({
                answer: answer1,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
              });
        }
        else  if(currentCategory==='WritingEmail'){
            if (wordCount2<120) {
                // Show a notification if no answer is selected
                toast.error("Minimum Word Count is 120");
                return; 
            }
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
              }
              updatedAns[currentCategory].push({
                answer: answer2,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
              });
        }
        else{
            if (wordCount3<300) {
                // Show a notification if no answer is selected
                toast.error("Minimum Word Count is 300");
                return; 
            }
            if (!updatedAns[currentCategory]) {
                updatedAns[currentCategory] = [];
              }
              updatedAns[currentCategory].push({
                answer: answer3,
                uniqueQuestionNumber: questions[currentQuestionIndex].uniqueQuestionNumber
              });
        }
        setAns(updatedAns)
        if (currentQuestionIndex < questions.length - 1) {
            // Move to next question in the same category
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentCategoryIndex < categories.length - 1) {
            // Move to first question of the next category
            setCurrentCategoryIndex(currentCategoryIndex + 1);
            setCurrentQuestionIndex(0);
        } else {
            setIsFinalStage(true);
        }
    }

    const formatCategory =(category) =>{
        if (typeof category === 'string') {
            return category.replace(/([a-z])([A-Z])/g, '$1 $2');
        } else {
            return ''; 
        }
    }

    const handleClose = () =>{
        setShowScorecard(false);
        navigate(-1)
    }

    if(loading){
        return(
            <LoadingSpinner/>
        )
    }
    if(overlayloading){
        return(
            <LoadingOverlay/>
        )
    }

  return (
    <div className='p-4'>
        <h1 className='font-semibold text-4xl'>Writing Test</h1>
        <h2 className='text-lg'>{formatCategory(categories[currentCategoryIndex])}</h2>
        <div className="question-container">
            {questionPaper && questions.length > 0 ? (
                <div>
                    <h3>Question {currentQuestionIndex + 1} of {questions.length }</h3>
                    {/* Render the current question based on its type */}
                    <div>
                       {renderTextBox(categories[currentCategoryIndex],questions[currentQuestionIndex])}
                    </div>
                    <div className="flex justify-end mt-4 ">
                        <button onClick={handleNext} className="py-2 bg-blue-500 text-white rounded-md px-4">
                            Analyse Answer
                        </button>
                    </div>
                </div>
            ) : (
                <div>No questions in this category.</div>
            )}
        </div>

        <ScoreCardMain
            show={showScorecard}
            data={scoreData}
            onClose={() => handleClose()}
            canRetry={false}
        />
        {isBuyMembership && (
            <BuyMembershipModal
                message={serverMessage}
                onClose={handleMembershipModalClose}
                onBuyMembership={handleBuyMembership}
            />
        )}
    </div>
  )
}

import React, { useState } from 'react';
import star from '../../../../assets/images/star.png';
import { ReactComponent as CloseGray } from '../../../../assets/svg/close_grey.svg';
const ScoreCard = ({ show, data, onClose ,onNext, onRetry, questionText}) => {

    const [showAnswers, setShowAnswers] = useState(false);

    const handleShowAnswers = () => {
        if (!showAnswers) {
            setShowAnswers(true);
        }
    };
    
    if (!show) return null;

    const { checkedAnswers, correctAnswers, overallResult } = data;


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-1/3 max-h-[90%] overflow-auto relative">
                <div className='flex justify-between '>
                    <h2 className="text-xl font-bold mb-2">Result</h2>
                    <CloseGray 
                        onClick={onClose} 
                        aria-label="Close" 
                        className="w-4 h-4 cursor-pointer m-1" 
                    />
                </div>
                <h3 className="mb-2 text-gray-500">Analyse your answers with the correct answers</h3>
                <div className="flex justify-center items-center mb-4">
                    <div className="w-32 h-32 bg-yellow-100 rounded-full flex items-center justify-center space-x-2 border border-orange-200">
                        {/* Circular background */}
                        <div className="text-3xl font-bold">
                            {overallResult}
                        </div>
                        {/* Star Icon */}
                        <div>
                            <img 
                                src={star}
                                alt="star-image"
                                className='w-10 h-10'
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-col space-y-4'>
                    <p className="font-bold ">Your Answers</p>
                    <div className="question-text">
                        {questionText.split(/(\*+)/).map((part, index) => {
                            // Check if the current part is an asterisk (star location)
                            if (index % 2 === 1) { // Only process parts that are at star positions
                                const answerIndex = Math.floor(index / 2); // Calculate the correct answer index

                                // Get the answer from checkedAnswers using the 1-based index
                                const currentAnswer = checkedAnswers[answerIndex + 1]; // Assuming checkedAnswers uses 1-based keys

                                return (
                                    <span key={index} className="inline-block mb-4"> {/* Added block display and margin-bottom */}
                                        {/* Display the answer with a background based on correctness */}
                                        <span
                                            className={`px-2 py-1 rounded-l-full rounded-r-full  ${
                                                currentAnswer && currentAnswer.Correct === '0' ? 'bg-green-100' : 'bg-red-100'
                                            }`}
                                        >
                                            <span
                                                className={` ${currentAnswer.Correct === '0' ? 'text-green-700' : 'text-red-700'}`}
                                            >
                                                {answerIndex + 1}. {currentAnswer ? currentAnswer.Answer : 'No Answer'}
                                            </span>
                                        </span>
                                    </span>
                                );
                            }

                            // Render plain text for non-star parts
                            return <span key={index}>{part}</span>;
                        })}
                    </div>
                    <div>
                        <button 
                            onClick={handleShowAnswers}
                            className="w-full py-2 border-2 border-gray-500  rounded-full hover:bg-blue-600 hover:text-white focus:outline-none"
                        >
                            Show all correct answers
                        </button>
                        
                        {showAnswers && (
                            <div className="flex-col space-y-4 py-4 mt-4">
                                {Object.entries(correctAnswers).map(([key, value], index) => (
                                    <div 
                                        className="flex items-center px-4 py-2 bg-gray-100 rounded-md" 
                                        key={index}
                                    >
                                        <span className="mr-2 font-semibold">{key}.</span>
                                        <span>{value}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <button
                        className="border-2 text-black py-2 px-8 rounded-md"
                        onClick={onNext}
                    >
                        Next
                    </button>
                    <button
                        className="bg-blue-600 text-white py-2 px-8 rounded-md"
                        onClick={onRetry}
                    >
                        Retry
                    </button>
                    
                </div>
            </div>
        </div>
    );
};

export default ScoreCard;

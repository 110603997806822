import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoginModal: false,
  showListeningModal: false,
  showCategoryChangeModal: false,
  showListeningTestRetakeAlert: false,
  listeningVideoDetails: {},
  readingData: [],
  isLoading: false,
  vocabularyOffcanvasContent: {},
  clickedButton: JSON.parse(localStorage.getItem("userData"))?.examType || '',
  activeCategory : JSON.parse(localStorage.getItem("userData"))?.examType || '',
  questions: [], // New property for storing questions
  catalogue: null,
  retry: false,
  numberOfTestsTaken: 0 
};

const otherSlice = createSlice({
  name: "other",
  initialState,
  reducers: {
    changeLoginModalStatus: (state, action) => {
      state.showLoginModal = action.payload;
    },
    changeListeningModalStatus: (state, action) => {
      state.showListeningModal = action.payload;
    },
    changeCategoryModalStatus: (state, action) => {
      state.showCategoryChangeModal = action.payload
    },
    changeShowListeningTestRetakeAlertStatus: (state, action) => {
      state.showListeningTestRetakeAlert = action.payload;
    },
    setListeningVideoDetails: (state, action) => {
      state.listeningVideoDetails = action.payload;
    },
    setReadingData: (state, action) => {
      state.readingData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setVocabularyOffcanvasContent: (state, action) => {
      state.vocabularyOffcanvasContent = action.payload;
    },
    setActiveExamType : (state,action) => {
      state.activeCategory = action.payload
    },
    resetOtherState: (state) => {
      state = initialState;
    },
    setClickedButton: (state, action) => { 
      state.clickedButton = action.payload; // Updated the clicked button state
    },
    setQuestions: (state, action) => {
      state.questions = action.payload.questions;
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
    setCatalogue: (state, action) => {
      state.catalogue = action.payload;
    },
    clearCatalogue: (state) => {
      state.catalogue = null;
    },
    setRetry: (state, action) => {
      state.retry = action.payload; // Update retry state
    },
    resetRetry: (state) => {
      state.retry = false; // Reset retry to false
    },
  },
});

export const {
  changeLoginModalStatus,
  setLoading,
  changeListeningModalStatus,
  changeCategoryModalStatus,
  resetOtherState,
  setListeningVideoDetails,
  setReadingData,
  setVocabularyOffcanvasContent,
  changeShowListeningTestRetakeAlertStatus,
  setActiveExamType,
  setClickedButton,
  setQuestions,
  clearQuestions,
  setCatalogue,
  clearCatalogue,
  setRetry,      // Export the new action for setting retry
  resetRetry,
} = otherSlice.actions;
export default otherSlice.reducer;

import React from 'react';
import './WritingCatalogue.css';

const LoadingOverlay = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="text-center">
                <div className="loader"></div>
                <p className="text-white text-lg mt-4">It takes 4-5 minutes to analyse your answer, Please wait do not go back.</p>
            </div>
        </div>
    );
};

export default LoadingOverlay;
